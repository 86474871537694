import FooterBottom from "./Components/FooterBottom";
import FooterLeft from "./Components/FooterLeft";
import FooterRight from "./Components/FooterRight";

import "./style.less";

const Footer = () => {
  return (
    <footer className="footer__container">
      <div className="footer__content">
        <div className="content__top">
          <FooterLeft />
          <FooterRight />
        </div>
        <FooterBottom />
      </div>
    </footer>
  );
};

export default Footer;

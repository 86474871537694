import FacebookIcon from "./Assets/Icons/icon_facebook.svg";
import InstagramIcon from "./Assets/Icons/icon_instagram.svg";

import AppStoreLogo from "./Assets/Imgs/logo_app-store.png";
import GooglePlayLogo from "./Assets/Imgs/logo_google-play.png";
import AppGalleryLogo from "./Assets/Imgs/logo_app-gallery.png";

export const FOOTER_RIGHT_LINKS = [
  {
    name: "Career",
    link: "https://careers.shopee.com.my/",
  },
  {
    name: "Customer Service",
    link: "/contact-us",
  },
  {
    name: "Frequently Asked Questions",
    link: "https://help.shopee.com.my/portal/4/category/31-Payments/667-Payment-options?page=1",
  },
];

export const FOOTER_LEFT_SOCIAL_MEDIA_LINKS = [
  {
    icon: FacebookIcon,
    alt: "facebook",
    link: "https://www.facebook.com/ShopeePayMY",
  },
  {
    icon: InstagramIcon,
    alt: "instagram",
    link: "https://www.instagram.com/shopeepay_my/",
  },
];

export const FOOTER_LEFT_DOWNLOAD_LINKS = [
  {
    logo: AppStoreLogo,
    alt: "app-store",
    link: "https://apps.apple.com/my/app/id959841113",
  },
  {
    logo: GooglePlayLogo,
    alt: "google-play",
    link: "https://play.google.com/store/apps/details?id=com.shopee.my&referrer=af_tranid%3DZPoWZKWO_aJuSqfj4uXOCw%26pid%3DOrganicA%26c%3Dlp_home_and1",
  },
  {
    logo: AppGalleryLogo,
    alt: "app-gallery",
    link: "https://appgallery.huawei.com/app/C100576469?sharePrepath=ag&channelId=web&detailType=0",
  },
];

import NavbarLeft from "./Components/NavbarLeft";
import NavbarRight from "./Components/NavbarRight";

import "./style.less";

const Navbar = () => {
  return (
    <header className="navbar__container">
      <div className="navbar__content">
        <div className="content__wrapper">
          <NavbarLeft />
          <NavbarRight />
        </div>
      </div>
    </header>
  );
};

export default Navbar;

import {
  FOOTER_LEFT_DOWNLOAD_LINKS,
  FOOTER_LEFT_SOCIAL_MEDIA_LINKS,
} from "../../constants";

import "./style.less";

const FooterLeft = () => {
  return (
    <div className="footer-left__content">
      <div className="content__social-media">
        <p>Follow us</p>
        <div className="social-media__links">
          {FOOTER_LEFT_SOCIAL_MEDIA_LINKS.map((item) => (
            <img
              key={item.alt}
              src={item.icon}
              alt={item.alt}
              onClick={() => window.open(item.link, "_blank")}
            />
          ))}
        </div>
      </div>
      <div className="content__downloads">
        <p>Download Shopee app now</p>
        <div className="downloads__links">
          {FOOTER_LEFT_DOWNLOAD_LINKS.map((item) => (
            <img
              key={item.alt}
              src={item.logo}
              alt={item.alt}
              onClick={() => window.open(item.link, "_blank")}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FooterLeft;

import "./style.less";

const FooterBottom = () => {
  return (
    <div className="footer-bottom__content">
      <div className="content__first">
        Copyright © Shopee Limited . Trademarks belong to their respective
        owners. All rights reserved. |{" "}
        <a href="/terms" target="_blank">
          Terms of Service
        </a>{" "}
        |{" "}
        <a href="/privacy" target="_blank">
          Privacy Policy
        </a>
      </div>
      <p>ShopeePay Malaysia Sdn Bhd | Company No: 201601007371 (1178299-D)</p>
      <p>
        SeaMoney Capital Malaysia Sdn. Bhd. | Company No: 202001010678
        (1366998-A)
      </p>
    </div>
  );
};

export default FooterBottom;

import "./style.less";

const NavbarHamburger = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const handleOnClick = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="navbar-hamburger__content">
      <input
        type="checkbox"
        className="content__checkbox"
        id="openSidebarMenu"
        checked={isSidebarOpen}
        onChange={handleOnClick}
      />

      <label className="content__icon" htmlFor="openSidebarMenu">
        <div className="spinner diagonal part-1" />
        <div className="spinner horizontal" />
        <div className="spinner diagonal part-2" />
      </label>

      {isSidebarOpen && (
        <div className="content__background" onClick={handleOnClick} />
      )}
    </div>
  );
};

export default NavbarHamburger;

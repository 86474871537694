import { Link } from "react-router-dom";

import SeaMoneyLogo from "../../Assets/Imgs/logo_seamoney.png";
import ShopeePayLogo from "../../Assets/Imgs/logo_shopeepay.png";

import "./style.less";

const NavbarLeft = () => {
  const handleOnClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="navbar-left__content">
      <Link to="/" onClick={handleOnClick}>
        <img className="logo" src={SeaMoneyLogo} alt="SeaMoney_Logo" />
      </Link>
      <Link to="/" onClick={handleOnClick}>
        <img className="logo" src={ShopeePayLogo} alt="ShopeePay_Logo" />
      </Link>
    </div>
  );
};

export default NavbarLeft;

import { FOOTER_RIGHT_LINKS } from "../../constants";

import "./style.less";

const FooterRight = () => {
  return (
    <div className="footer-right__content">
      <h3>Explore ShopeePay & SeaMoney</h3>
      <div className="content__links">
        {FOOTER_RIGHT_LINKS.map((item) => (
          <a key={item.name} href={item.link} target="_blank">
            {item.name}
          </a>
        ))}
      </div>
    </div>
  );
};

export default FooterRight;

import { useEffect, useState } from "react";
import Media from "react-media";
import { Link, useLocation } from "react-router-dom";

import UpChevronIcon from "../../../Icons/UpChevronIcon";

import { NAVBAR_MENU } from "../../constants";

import NavbarHamburger from "../NavbarHamburger";

import "./style.less";

const NavbarRight = () => {
  const location = useLocation();

  const [activeMenu, setActiveMenu] = useState("Home");

  // For Mobile
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleOnClick = (link) => {
    setActiveMenu(link.name === activeMenu ? "" : link.name);
    if (!link.children) window.scrollTo(0, 0);
  };

  useEffect(() => {
    setIsSidebarOpen(false);
    setActiveMenu("");
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const getLinkContent = (link) => {
    const currPath = location.pathname;

    const isActive = currPath === link.path;

    const generalLinkProps = {
      className: "link__label" + (isActive ? " active" : ""),
      key: link.path,
      name: link.name,
      target: link.isExternal ? "_blank" : "",
      rel: "noopener noreferrer",
      onClick: () => handleOnClick(link),
    };

    return link.isExternal ? (
      <a href={link.path} {...generalLinkProps}>
        {link.name}
      </a>
    ) : (
      <Link to={link.path} {...generalLinkProps}>
        {link.name}
      </Link>
    );
  };

  const getMenuContent = (link) => {
    const currPath = location.pathname;

    const isActive = currPath.startsWith(link.path);

    const isOpen = activeMenu === link.name;

    return link.children ? (
      <div
        className="link__wrapper"
        onClick={() => handleOnClick(link)}
        key={link.name}
      >
        <div
          className={
            "link__label" +
            (isActive ? " active" : "") +
            (isOpen ? " open" : "")
          }
        >
          <span>{link.name}</span>
          <UpChevronIcon />
        </div>

        <div className={"link__children" + (isOpen ? " open" : "")}>
          <div className="children__content">
            {link.children.map((child) => getLinkContent(child))}
          </div>
        </div>
      </div>
    ) : (
      getLinkContent(link)
    );
  };

  return (
    <Media
      queries={{
        medium: "(max-width: 1000px)",
        large: "(min-width: 1001px)",
      }}
    >
      {(matches) => (
        <div className="navbar-right__content">
          {matches.large && (
            <div className="content__desktop">
              {NAVBAR_MENU.map((menu) => getMenuContent(menu))}
            </div>
          )}

          {matches.medium && (
            <>
              <NavbarHamburger
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
              />
              <div className="content__mobile">
                <div className="content__sidebar">
                  {NAVBAR_MENU.map((menu) => getMenuContent(menu))}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </Media>
  );
};

export default NavbarRight;
